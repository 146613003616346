<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>用户建议</h5>
    </div>
    <div class="line"></div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="userName" label="建议人" width="140" />
        <el-table-column prop="content" label="建议内容" />
        <el-table-column prop="createTime" label="创建时间" width="180" />
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pageSize"
      :current-page="pageNum" layout="total,sizes, prev, pager, next" :total="total">
    </new-pagination>
  </div>
</template>

<script>
import api from "@/api/index";
export default {
  data() {
    return {
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
    };
  },
  created() {
    this.getAllList()
  },
  methods: {
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAllList();
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getAllList();
    },
    //获取列表
    async getAllList() {
      let p = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      let res = await api.getSuggestList(p);

      if (res.code == 200) {
        this.tableData = res.rows;
        this.total = res.total;
      } else {
        this.tableData = [];
        this.total = 0;
      }
    },
  },
};
</script>

<style scoped lang="less">
/*页面特殊样式*/
.one_tag,
.two_tag,
.three_tag {
  padding: 5px 12px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  color: #fff;
}

.one_tag {
  background: #c60c00;
  background: -webkit-linear-gradient(to bottom, #eb1610, #c66334);
  background: linear-gradient(to bottom, #eb1600, #c65f00);
}

.two_tag {
  background: #eb6601;
  background: -webkit-linear-gradient(to bottom, #eb6601, #c6aa07);
  background: linear-gradient(to bottom, #eb6601, #c6aa07);
}

.three_tag {
  background: #ebd007;
  background: -webkit-linear-gradient(to bottom, #ebd007, #bec606);
  background: linear-gradient(to bottom, #ebd007, #bec606);
}

/deep/.drawClass {
  .el-drawer__body {
    height: 100%;
    padding: 0 10px 20px 10px;

    .content {
      height: 100%;

      .el-tree {
        height: 90%;
      }
    }
  }
}

.tbale_box {
  height: calc(100% - 100px);
}
</style>
